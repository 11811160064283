import { Link } from '@travelpass/design-system'
import { privacyNoticePath, supportPath } from 'src/constants'

const smoothScrollTo = href => e => {
  e.preventDefault()
  const target = document?.querySelector(href)
  if (target) {
    target?.scrollIntoView({
      behavior: 'smooth',
    })
  }
}

export const PrivacyContent = () => {
  const constructPrivacyContent = (
    <div className='pb-20'>
      <div className='text-right'>
        <Link
          label='Scroll to bottom'
          onClick={smoothScrollTo('#privacy-policy-end')}
        />
      </div>
      <div className='type-body-1 mt-4'>
        Thanks for using Travelpass.com. We&apos;ve developed a policy that
        explains how and why we use the information that comes through our site.
        It applies to the services available through the Travelpass.com website,
        its mobile site, and all other applications and online communications,
        which we refer to as our “Site” in this policy. We respect your
        expectations and take your privacy seriously.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>The Basics</h4>
      <div className='type-body-1 mt-4'>
        We will not ask you for information that does not have a purpose.
        Whether you travel the web or travel the world, Travelpass.com wants you
        to always be safe!
      </div>
      <div className='type-body-1 mt-4'>
        By visiting our Site, you consent to the collection, use, disclosure,
        storage, transfer and processing of your personal data described in this
        policy. Our Site includes links to other websites and online services
        whose privacy practices may differ from those of Travelpass.com. We
        encourage you to carefully read the privacy policy of any website you
        visit or online services you use.
      </div>
      <div className='type-body-1 mt-4'>
        Please take a moment to familiarize yourself with our privacy practices.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        Information We Collect
      </h4>
      <div className='type-body-1 mt-4'>
        We collect personally identifiable information (“Personal Data”) about
        you that you provide to us while using our Site. Personal Data includes
        information that can identify you as a specific individual, such as your
        name, address, phone number, credit card number, IP address, or e-mail
        address. We also collect non-personally identifiable information,
        including your search preferences related to specific searches,
        location, and bids through your interactions with our Site. When we link
        non-personally identifiable information with Personal Data, we will
        treat it as Personal Data under this Privacy Policy.
      </div>
      <div className='type-body-1 mt-4'>
        Our Site also includes Social Media Features and Widgets, such as
        Facebook, Google Plus, Twitter, and YouTube. These features may collect
        your IP address, which page you are visiting on our Site, and may set a
        cookie to enable the feature to function properly. Social Media Features
        and Widgets are either hosted by a third party or hosted directly on our
        Site. Your interactions with these features are governed by the privacy
        policy of the company providing it.
      </div>
      <div className='type-body-1 mt-4'>
        We also may periodically obtain both personal and non-personal
        information about you from affiliated entities, business partners and
        other independent third-party sources and add it to our account
        information. Examples of information we may receive include: updated
        delivery and address information, purchase history, and demographic
        information.
      </div>
      <div className='type-body-1 mt-4'>
        We automatically collect some information about your computer when you
        visit this website. For example, we will collect your IP address, web
        browser software (such as Firefox, Safari, or Internet Explorer), and
        referring website. We also may collect information about your online
        activity, such as hotels viewed and bookings made.
      </div>
      <div className='type-body-1 mt-4'>
        Our goals in collecting this automatic information include helping
        understand your needs, customize your user experience and interaction
        with our Site, and inhibiting fraud.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        How We Protect Your Personal Data
      </h4>
      <div className='type-body-1 mt-4'>
        We want you to feel confident about using our site to make travel
        arrangements, and we are committed to protecting the information we
        collect. While no website can guarantee security, we have implemented
        appropriate administrative, technical, and physical security controls to
        help protect the personal information you provide to us. For example,
        only authorized employees are permitted to access personal information,
        and they may only do so for permitted business functions. In addition,
        we use encryption when transmitting your sensitive personal information
        between your system and ours, and we employ firewalls and intrusion
        detection systems to help prevent unauthorized persons from gaining
        access to your information.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        How We Use the Information We Collect
      </h4>
      <div className='type-body-1 mt-4'>
        We use sensitive billing information (such as cardholder name, credit
        card number, and expiration date) for the purpose of completing the
        travel bookings you conduct on our Site. We use other information about
        you for the following general purposes: to provide you with the products
        and services you request; to provide you with travel confirmation and
        updates; to manage your account, including processing bills and
        providing travel notifications; to communicate with you in general; to
        respond to your questions and comments; to measure interest in and
        improve our products, services, and website; to notify you about special
        offers and products or services that may be of interest to you; to
        otherwise customize your experience with this website; to solicit
        information from you, including through surveys; to resolve disputes,
        collect fees, or troubleshoot problems; to prevent potentially
        prohibited or illegal activities; to enforce our Terms of Use; and as
        otherwise described to you at the point of collection.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        Sharing Your Personal Data
      </h4>
      <div className='type-body-1 mt-4'>
        Travelpass.com does not share your Personal Data, except as disclosed in
        this Privacy Policy or as otherwise authorized by you. Travelpass.com
        may share your Personal Data with the following entities:
        <ul className='[&>li]:px-0 [&>li]:py-2'>
          <li>
            <u>Suppliers</u>, such as hotels, online travel agencies, and hotel
            wholesalers, who fulfill your travel reservations. Throughout this
            Site, all services provided by a third-party supplier are described
            as such. By making a reservation through this Site, you are
            authorizing us to disclose to suppliers the information required to
            complete the booking and deliver the related travel. These suppliers
            are not controlled by Travelpass.com and personal information
            disclosed to them is subject to the applicable supplier&apos;s
            privacy policy and security practices. Therefore, we encourage you
            to review the privacy policies of any travel supplier whose products
            you purchase through this Site.
          </li>
          <li>
            <u>Third-party vendors</u> who provide services or functions on our
            behalf, including credit card processing, business analytics,
            customer service, marketing, distribution of surveys or sweepstakes
            programs, and fraud prevention. We may also authorize third-party
            vendors to collect information on our behalf, including as necessary
            to operate features of our website or to facilitate the delivery of
            online advertising tailored to your interests. Third-party vendors
            have access to and may collect information only as needed to perform
            their functions and are not permitted to share or use the
            information for any other purpose. They are also required to follow
            the same data security practices that we ourselves adhere to.
          </li>
          <li>
            <u>Business partners</u> with whom we may jointly offer products or
            services, or whose products or services may be offered on our
            website. You can tell when a third party is involved in a product or
            service you have requested because their name will appear, either
            alone or with ours. If you choose to access these optional services,
            we may share information about you, including your personal
            information, with those partners. Please note that we do not control
            the privacy practices of these third-party business partners.
          </li>
          <li>
            <u>Referring websites</u>. If you were referred to this website from
            another site (for example, through a link you clicked on another
            Site that directed you to this one), we may share some information
            about you with that referring website. We have not placed
            limitations on the referring websites use of your personal
            information and we encourage you to review the privacy policies of
            any website that referred you here.
          </li>
          <li>
            <u>Companies within our corporate family</u>. We may share your
            personal information with our parent company and corporate
            affiliates, including Travelpass Group, Inc. This sharing enables us
            to provide you with information about products and services, both
            travel-related and other, which might interest you. To the extent
            that our parent company and corporate affiliates have access to your
            information, they will follow practices that are at least as
            restrictive as the practices described in this Privacy Policy. They
            also will comply with applicable laws governing the transmission of
            promotional communications.
          </li>
        </ul>
        We may also share your information:
        <ul className='[&>li]:px-0 [&>li]:py-2'>
          <li>
            In response to subpoenas, court orders, or other legal process; to
            establish or exercise our legal rights; to defend against legal
            claims; or as otherwise required by law. In such cases we reserve
            the right to raise or waive any legal objection or right available
            to us.
          </li>
          <li>
            When we believe it is appropriate to investigate, prevent, or take
            action regarding illegal or suspected illegal activities; to protect
            and defend the rights, property, or safety of our company or this
            website, our customers, or others; and in connection with our Terms
            of Service and other agreements.
          </li>
          <li>
            In connection with a corporate transaction, such as a divestiture,
            merger, consolidation, or asset sale, or in the unlikely event of
            bankruptcy.
          </li>
        </ul>
        We may also share aggregate or anonymous information with third parties,
        including advertisers and investors. For example, we may tell our
        advertisers the number of visitors our Site receives or the most popular
        hotels and vacation destinations. This information does not contain any
        personal information and is used to develop content and services we hope
        you will find of interest.
      </div>
      <section>
        <h4
          className='type-h4 c-forest-dark mb-4 mt-16 uppercase'
          id='account-deletion'
        >
          Account Deletion
        </h4>
        <p>
          At Travelpass.com, we respect your privacy and value your control over
          your personal information. We understand that circumstances may arise
          where you no longer wish to use our services, and we provide a
          convenient option for deleting your account. This section outlines the
          process and timeline for deleting your account and the permanent
          removal of associated personal information.
        </p>
        <h5 id='account-deletion-process'>Account Deletion Process</h5>
        <ol className='list-alpha'>
          <li>Log in to your account.</li>
          <li>
            Navigate to your Profile and click on &quot;Login Security&quot;.
          </li>
          <li>Locate the &quot;Delete Account&quot; button and select it.</li>
          <li>
            You will be prompted to confirm the deletion of your account. Please
            note that this action is irreversible and will result in the
            permanent removal of your personal information from our systems.
          </li>
          <li>
            Once you confirm the deletion, your account will be scheduled for
            removal.
          </li>
        </ol>
        <h5 id='timeline-for-account-deletion'>
          Timeline for Account Deletion
        </h5>
        <p>
          Upon initiating the account deletion process, Travelpass.com will
          begin the necessary procedures to permanently delete your personal
          information from our systems. Please note the following details
          regarding the timeline:
        </p>
        <ol className='list-alpha'>
          <li>
            Your account data will remain in our system for a period of no more
            than 90 days from the date of deletion confirmation. During this
            time, you will not be able to access your account or use any
            associated services.
          </li>
          <li>
            By the end of the 90-day period, all personal information associated
            with your account will be permanently deleted from our systems. This
            includes your name, contact details, preferences, billing details,
            and other information you provided to us.
          </li>
          <li>
            Following the deletion of your account, we will no longer retain any
            records or backups containing your personal information.
          </li>
        </ol>
        <h5 id='implications-of-account-deletion'>
          Implications of Account Deletion
        </h5>
        <p>
          Deleting your account on Travelpass.com will not automatically cancel
          or modify any existing bookings. Therefore, it is important to
          understand the following implications:
        </p>
        <ol className='list-alpha'>
          <li>
            Once your account is deleted, you will no longer have access to your
            booking details, itineraries, or any associated benefits through
            your account.
          </li>
          <li>
            Any pending or upcoming bookings that have not been managed or
            canceled prior to account deletion will remain active.
          </li>
          <li>
            It is your responsibility to contact Travelpass.com support to
            manage or cancel your bookings independently.
          </li>
          <li>
            Any applicable terms and conditions, cancellation policies, or fees
            set by the service providers will still apply to your bookings.
          </li>
          <li>
            Travelpass.com will not be liable for any losses, costs, or
            inconveniences incurred due to the deletion of your account without
            managing or canceling your bookings.
          </li>
        </ol>
        <h5 id='data-retention-exceptions'>Data Retention Exceptions</h5>
        <p>
          Travelpass.com is committed to complying with applicable laws and
          regulations. In certain cases, we may be required to retain certain
          personal information for a longer duration to fulfill legal
          obligations or to resolve disputes or claims. However, such retention
          will be limited to the extent necessary and in accordance with legal
          requirements.
        </p>
        <h5>Effect on Data Sharing and Third Parties</h5>
        <p>
          Upon deleting your account, your personal information will no longer
          be shared with any third parties unless required by law or necessary
          to comply with legal obligations. Any ongoing or future partnerships
          or data sharing arrangements will be terminated upon account deletion.
        </p>
        <h5 id='account-reactivation'>Account Reactivation</h5>
        <p>
          Once your account has been deleted, it cannot be recovered. To
          continue using Travelpass.com services in the future, you will need to
          create a new account.
        </p>
        <p>
          If you have any questions or concerns regarding the deletion of your
          account or the privacy of your data, please contact our support team.
        </p>
      </section>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        Cookies and Other Technologies
      </h4>
      <div className='type-body-1 mt-4'>
        Cookies and related tracking technologies, such as beacons, scripts, and
        tags, are small bits of code, usually stored on a user&apos;s computer
        hard drive or device, which enable a website to “personalize” itself for
        each user by remembering information about the user&apos;s visit to the
        website.
      </div>
      <div className='type-body-1 mt-4'>
        As described in more detail below, our Site uses cookies to store your
        preferences, display content based upon what you view on our Site to
        personalize your visit, analyze trends, administer our Site, track user
        movements around our Site, serve targeted advertising and gather
        demographic information about our user base as a whole. On mobile
        websites and mobile apps, we may use Anonymous Device IDs and/or
        Advertiser Identifiers in a manner similar to our use of cookies on our
        websites. To opt-out of advertising and certain other cookies, please
        see the opt-out links below.
      </div>
      <div className='type-body-1 mt-4'>
        <strong>Travelpass.com Cookies (First Party Cookies):</strong>{' '}
        Travelpass.com uses its cookies to improve your web-browsing experience.
        We will also use a cookie to remember of your search criteria while you
        are engaging Travelpass.com&apos;s services. Travelpass.com cookies are
        associated with a user&apos;s Personal Data. However, no third party,
        other than companies in our corporate family, may use the information we
        collect through our Travelpass.com cookies for their own purposes.
      </div>
      <div className='type-body-1 mt-4'>
        <strong>Third Party Cookies:</strong> We work with third-parties that
        place cookies on our Site to provide their services, including:
        <ul className='[&>li]:px-0 [&>li]:py-2'>
          <li>
            <strong>Ad Targeting:</strong> We work with third-party advertising
            companies to serve ads while you are visiting our Site and permit
            these companies to place and access their own cookies on your
            computer in the course of serving advertisements on this Site. These
            companies may use information obtained through their cookie (which
            does not include your name, address, e-mail address or telephone
            number) about your visits to our and other websites, in combination
            with non-personally identifiable information about your purchases
            and interests from other online Sites in order to provide
            advertisements about goods and services of interest to you. Other
            companies&apos; use of their cookies is subject to their own privacy
            policies and not ours. Data Providers: We also allow data providers
            to collect web log data from you (including IP address and
            information about your browser or operating system), when you visit
            our Site, or place or recognize a unique cookie on your browser to
            enable you to receive customized ads or content. These cookies
            contain no personally identifiable information. The cookies may
            reflect de-identified demographic or other data linked to data you
            voluntarily have submitted to us, e.g., your email address, that we
            may share with data providers solely in hashed, non-human readable
            form.
          </li>
          <li>
            <strong>Analytics/Measurement:</strong> We also use third-party
            analytics cookies to gain insight into how our visitors use our
            Site, to find out what works and what doesn&apos;t, to optimize and
            improve our websites and to ensure we continue to be interesting and
            relevant. The data we gather includes which web pages you have
            viewed, which referring/exit pages you have entered and left from,
            which platform type you have used, date and time stamp information
            and details such as the number of clicks you make on a given page,
            your mouse movements and scrolling activity, the search words you
            use and the text you type while using our Site. We also make use of
            analytics cookies as part of our online advertising campaigns to
            learn how users interact with our website after they have been shown
            an online advertisement, which may include advertisements on
            third-party websites.
          </li>
          <li>
            <strong>Remarketing Pixel Tags:</strong> In addition, we may share
            Site usage information about visitors to our Sites with third-party
            advertising companies for the purpose of managing and targeting
            advertisements and for market research analysis on our Site and
            other sites. For these purposes, we and our third-party advertising
            companies may place pixel tags (also called clear gifs) on some of
            the pages you visit on our Site. We will then use the non-personally
            identifiable information that is collected using these pixel tags to
            serve you relevant advertisements when you are visiting other Sites
            on the Internet. These advertisements may be targeted to specific
            searches you conducted on Travelpass.com during earlier browsing
            sessions.
          </li>
          <li>
            <strong>Flash Cookies:</strong> Our partners, who provide certain
            features on our Site or display advertising based on your browsing
            activity, and Travelpass.com also use Local Shared Objects such as
            Flash cookies, and Local Storage such as HTML5, to collect and store
            content information and preferences. Various browsers may offer
            their own management tools for removing HTML5 local storage. To
            manage Flash cookies, please{' '}
            <Link
              href='http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html'
              label='click here'
              target='_blank'
            />
            .
          </li>
        </ul>
        <strong>Opting Out of Cookies:</strong> We provide you with choice over
        the use of cookies as described in this Privacy Policy. However, our
        Site is not currently configured to respond to Do Not Track signals. If
        you&apos;d rather we didn&apos;t use Travelpass.com cookies when you
        visit us, please configure your specific browser settings to reject
        cookies.
      </div>
      <div className='type-body-1 mt-4'>
        If you would like to opt-out of third-party cookies, here are some
        options:
        <ul className='[&>li]:px-0 [&>li]:py-2'>
          <li>
            If you would like to opt-out of ad targeting cookies set by Google
            and Doubleclick on our Site, please click here:{' '}
            <Link
              href='https://www.google.com/settings/u/0/ads?hl=en'
              label='https://www.google.com/settings/u/0/ads?hl=en'
              target='_blank'
            />
          </li>
          <li>
            If you would like to disable the display of advertisements served by
            Criteo, please click here:{' '}
            <Link
              href='https://www.criteo.com/privacy/'
              label='https://www.criteo.com/privacy/'
              target='_blank'
            />
          </li>
          <li>
            If you would like to generally (i.e. not just for this Site) opt-out
            of receiving personalized ads from third party advertisers and ad
            networks who are members of the Network Advertising Initiative (NAI)
            or who follow the Digital Advertising Alliance&apos;s (DAA)
            Self-Regulatory Principles for Online Behavioral Advertising by
            visiting the opt-out pages on the NAI website and DAA website or{' '}
            <Link
              href='https://preferences-mgr.truste.com/'
              label='click here'
              target='_blank'
            />
            . If you are located in the European Union, please{' '}
            <Link
              href='https://www.youronlinechoices.eu/'
              label='click here'
              target='_blank'
            />{' '}
            to opt out.
          </li>
          <li>
            In order to control the collection of data for analytical purposes
            by Google Analytics, you may want to visit the following link:{' '}
            <Link
              label='https://tools.google.com/dlpage/gaoptout'
              onClick={() =>
                window.open('https://tools.google.com/dlpage/gaoptout')
              }
            />
          </li>
          <li>
            If you wish to block other cookies, including those being served by
            companies that are not part of the NAI or DAA, please configure your
            specific browser settings to reject cookies. You can also delete all
            cookies that are already on your computer&apos;s hard drive by
            searching for and deleting files with “cookie” in it.
          </li>
        </ul>
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        Mobile Devices
      </h4>
      <div className='type-body-1 mt-4'>
        Anonymous Device Identifiers and Hashed IDs: We and/or data providers
        that we have engaged may collect and store a unique identifier matched
        to your mobile device, in order to deliver customized ads or content
        while you use applications or surf the internet, or to identify you in a
        unique manner across other devices or browsers. In order to customize
        these ads or content, we or a data partner may collect de-identified
        demographic or other data about you (received from third parties) either
        to data you voluntarily have submitted to us, e.g., your email address,
        or to data passively collected from you, such as your device identifier
        or IP address. However, we will share your email address and/or mobile
        phone number solely in hashed, non-human readable form.
      </div>
      <div className='type-body-1 mt-4'>
        If you no longer wish to receive interest-based advertising on your
        mobile device browser or applications, please refer to your
        device&apos;s operating system settings, or follow instructions below.
      </div>
      <div className='type-body-1 mt-4'>
        Android Users (version 2.3 and above): To use the “opt-out of
        interest-based advertising” option, follow the instructions provided by
        Google here:{' '}
        <Link
          label='Google Play Help'
          onClick={() =>
            window.open('https://support.google.com/googleplay/answer/3405269')
          }
        />
      </div>
      <div className='type-body-1 mt-4'>
        iOS users (version 6 and above): To use the “Limit Ad-Tracking” option,
        follow the instructions provided by Apple here:{' '}
        <Link
          label='Apple Support Center'
          onClick={() =>
            window.open('https://support.apple.com/en-us/HT202074')
          }
        />
      </div>
      <div className='type-body-1 mt-4'>
        Please keep in mind that, without cookies, you may not have access to
        certain features on our Site, including access to your profile/account
        and certain personalized content. Removing all cookies from your
        computer could also affect your subsequent visits to certain web Sites,
        including the Travelpass.com web Site.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        Children&apos;s Privacy
      </h4>
      <div className='type-body-1 mt-4'>
        This is a general audience website and does not offer services directed
        to children. Should a child whom we know to be under 13 send personal
        information to us, we will use that information only to respond directly
        to that child to inform him or her that we must have parental consent
        before receiving his or her personal information.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        California Residents - Your California Privacy Rights
      </h4>
      <div className='type-body-1 mt-4'>
        California residents, please{' '}
        <Link href={privacyNoticePath} label='click here' target='_blank' /> to
        view the California Privacy Notice.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        Changes To The Privacy Policy
      </h4>
      <div className='type-body-1 mt-4'>
        On occasion, Travelpass.com may revise this Privacy Policy to reflect
        changes in the law, our Personal Data collection and use practices, the
        features of our Site, or advances in technology. If we make any material
        changes we will notify you by means of a prominent notice on this Site
        prior to the change becoming effective.
      </div>
      <h4 className='type-h4 c-forest-dark mb-4 mt-16 uppercase'>
        How To Contact Us
      </h4>
      <p className='type-body-1 mt-4'>
        If you have any questions, concerns or comments, please{' '}
        <Link href={supportPath} label='click here' />. Travelpass.com is a
        website owned, maintained, and operated by TravelPass Group, Inc. and is
        located at:
        <br />
        <br />
        <address className='type-body-2'>
          4700 West Daybreak Parkway
          <br />
          STE 100 N
          <br />
          South Jordan, UT 84009
        </address>
      </p>

      <p className='type-body-1 mt-16 text-center' id='privacy-policy-end'>
        END OF PRIVACY POLICY
      </p>
    </div>
  )

  return constructPrivacyContent
}
