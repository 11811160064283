import { Icon, Modal, ModalScrollContents } from '@travelpass/design-system'
import type { StandardHotel } from 'src/__generated__/graphql'
import {
  constructAdditionalHotelAmenities,
  constructPopularHotelAmenities,
} from 'src/utils'

interface HotelAmenitiesModalProps {
  amenities: StandardHotel['amenities']
  onClose(): void
}

export const HotelAmenitiesModal = ({
  amenities,
  onClose,
}: HotelAmenitiesModalProps) => {
  const updatedAmenities = constructAdditionalHotelAmenities(amenities)

  const additionalAmenities = updatedAmenities?.map((amenity, index) => (
    <li key={`${amenity}-${index}`} className='type-body-1 c-forest truncate'>
      {amenity}
    </li>
  ))

  const popularAmenities = constructPopularHotelAmenities(amenities)?.map(
    ({ icon, label }, index) => (
      <div
        key={`${label}-${index}`}
        className='type-body-1 c-forest flex flex-row items-center gap-2'
      >
        <Icon name={icon} />
        <p>{label}</p>
      </div>
    )
  )

  return (
    <Modal size='medium' title='Amenities' onDismiss={onClose}>
      <ModalScrollContents>
        <div className='rebrand sm:space-y-6 lg:space-y-9'>
          <div className='space-y-4'>
            <h6 className='type-h4'>Popular Amenities</h6>
            <div className='gap-y-6px gap-x-8px auto-flow-dense m-0 grid list-inside p-0 sm:grid-cols-1 lg:grid-cols-2'>
              {popularAmenities}
            </div>
          </div>
          <div className='space-y-4'>
            <h6 className='type-h4'>Additional Amenities</h6>
            <ul className='gap-y-6px gap-x-8px auto-flow-dense m-0 grid list-inside p-0 sm:grid-cols-1 lg:grid-cols-2'>
              {additionalAmenities}
            </ul>
          </div>
        </div>
      </ModalScrollContents>
    </Modal>
  )
}
