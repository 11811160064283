import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getCurrentUserId = gql(`
  query GetCurrentUserId {
    currentUser {
      id
    }
  }
`)

export const useGetCurrentUserId = () => {
  const query = useQuery(getCurrentUserId)

  return query
}
