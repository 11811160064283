import isEmpty from 'lodash.isempty'
import type {
  GenericAddress,
  Guide,
  UserProfile,
} from 'src/__generated__/graphql'
import { guidesPath, guideDraftPath, profilePath } from 'src/constants'
import { DEFAULT_PROFILE_IMAGE_URL } from 'src/pages/profile/profileConstants'
import { copyToClipboard } from './clipboardUtil'
import { getDateFromUTC } from './dateUtils'

const decodeGuideId = (guideId: string) => {
  const guideDraftPrefix = 'GuideDraft:gded_'
  const guidePrefix = 'Guide:gde_'

  if (!guideId) return ''

  const decodedGuideIdRaw = atob(guideId)

  if (
    decodedGuideIdRaw.includes(guideDraftPrefix) ||
    decodedGuideIdRaw.includes(guidePrefix)
  )
    return decodedGuideIdRaw
      .replace(guideDraftPrefix, '')
      .replace(guidePrefix, '')

  return guideId
}

const encodeGuideDetailsName = (name: string) => {
  let nameFormatted =
    name?.replace(/\./g, '-').split(' ').join('-').replace(/-+/g, '-') ?? ''

  if (nameFormatted?.endsWith('-')) {
    nameFormatted = nameFormatted.slice(0, -1)
  }

  return encodeURIComponent(nameFormatted)
}

const encodeGuideId = ({
  guideId,
  isGuideDraft,
}: {
  guideId: string
  isGuideDraft: boolean
}) => {
  const guideDraftPrefix = 'GuideDraft:gded_'
  const guidePrefix = 'Guide:gde_'

  if (!guideId) return ''

  const decodedGuideId = atob(guideId)

  if (
    decodedGuideId.startsWith(guideDraftPrefix) ||
    decodedGuideId.startsWith(guidePrefix)
  )
    return guideId

  if (isGuideDraft) return btoa(`${guideDraftPrefix}${guideId}`)

  return btoa(`${guidePrefix}${guideId}`)
}

const getGuideDetailsUrl = ({
  id,
  name,
  isNameQueryParam = false,
}: {
  id: Guide['id']
  name?: Guide['name']
  isNameQueryParam?: boolean
}) => {
  const encodedName = encodeGuideDetailsName(name)

  if (!name) return `${guidesPath}/${id}`

  if (isNameQueryParam) return `${guidesPath}/${id}?name=${encodedName}`

  return `${guidesPath}/${id}/${encodedName}`
}

const getGuideAddress = ({
  city,
  country,
  state,
}: Pick<GenericAddress, 'city' | 'country' | 'state'>): string => {
  // solution based on this criteria => https://linear.app/travelpass/issue/BOOK-2541/guide-card-isnt-displaying-location-when-guide-only-has-state#comment-74b65772
  if (country === 'United States')
    return [city, state, !city && country].filter(Boolean).join(', ')

  return [city, !city && state, country].filter(Boolean).join(', ')
}

const getGuideOwnerImage = (
  profileImageUrl: Guide['ownerProfile']['profileImageUrl']
) => profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL

const getGuideOwnerName = ({
  accountHandle,
  displayName,
}: Pick<Guide['ownerProfile'], 'accountHandle' | 'displayName'>) => {
  if (displayName) return displayName

  if (accountHandle) return accountHandle

  return 'Anonymous'
}

const getGuideOwnerUrl = (
  accountHandle: Guide['ownerProfile']['accountHandle']
) => {
  if (accountHandle) return `${profilePath}/${accountHandle}`

  return profilePath
}

const getGuidePlaceLatLng = (
  location?: google.maps.LatLng | google.maps.LatLngLiteral
): google.maps.LatLngLiteral => {
  if (isEmpty(location)) return null

  if (
    typeof location?.lat === 'function' ||
    typeof location?.lng === 'function'
  ) {
    const { lat, lng } = (location as google.maps.LatLng) ?? {}

    return {
      lat: lat(),
      lng: lng(),
    }
  }

  const { lat, lng } = (location as google.maps.LatLngLiteral) ?? {}

  return {
    lat,
    lng,
  }
}

const getGuidePublishedDate = ({
  insertedAt,
  timeZone,
  updatedAt,
}: Pick<Guide, 'insertedAt' | 'timeZone' | 'updatedAt'>) => {
  const date = getDateFromUTC(updatedAt ?? insertedAt, timeZone)

  return date.format('MMMM D, YYYY')
}

const getGuideDraftDetailsUrl = ({
  id,
  isUserOwner = false,
  name,
}: {
  id: Guide['id']
  isUserOwner?: UserProfile['isUserOwner']
  name: Guide['name']
}) => {
  const encodedName = encodeGuideDetailsName(name)

  if (isUserOwner) return `${guideDraftPath}/${id}/${encodedName}`

  return `${guideDraftPath}/${id}/${encodedName}/share`
}

const shareGuide = async ({
  guideDraftTitle,
  isUserOwner,
  shareUrl,
  onSuccessfulShare,
}) => {
  if (navigator?.share) {
    navigator.share({
      title: guideDraftTitle,
      text: isUserOwner
        ? `Check out my "${guideDraftTitle}" guide on Travelpass!`
        : `Check out this "${guideDraftTitle}" guide I found on Travelpass!`,
      url: shareUrl,
    })
  } else {
    copyToClipboard(shareUrl)
    onSuccessfulShare()
  }
}

export {
  decodeGuideId,
  encodeGuideDetailsName,
  encodeGuideId,
  getGuideAddress,
  getGuideDetailsUrl,
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
  getGuidePlaceLatLng,
  getGuidePublishedDate,
  getGuideDraftDetailsUrl,
  shareGuide,
}
