import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { defaultTimeFormat } from 'src/common/components/TimeRangePicker/timeRangeConstants'
import {
  initialArrivalDate,
  initialDepartureDate,
  todayDate,
  maxDate,
} from 'src/constants/user'
export const extendDayjs = () => {
  dayjs.extend(advancedFormat)
  dayjs.extend(timezone)
  dayjs.extend(utc)
  dayjs.extend(customParseFormat)
}
const defaultTimeZone = 'Etc/UCT'
const timeRegex = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9](?: (?:AM|PM))?$/i
export const FOUR_DIGIT_YEAR = 'dddd'
export const MONTH_DAY = 'M/DD'
export const GRAPHQL_DATE_FMT =
  'YYYY-MM-DD' /** @todo research which Graphql/Raven Date fields this is and isn't compatible with. Some might be more complex. */

/** @deprecated just call .format() on the Dayjs value. consider using `import { GRAPHQL_DATE_FMT } from 'src/utils/dateUtils'` as a default format value. */
export const formatDate = (date: Dayjs, format = 'YYYY-MM-DD') =>
  date.format(format)

export const getDateDiff = (dates: [string, string]) =>
  dayjs(dates[0]).diff(dayjs(dates[1]), 'd')

export const localizedDateWithFullMonth = (date: Date) => {
  return dayjs(date).utc(true).format('MMMM D, YYYY')
}

export const localizedTime = (time: Date) => {
  return dayjs(time).utc(true).format('h:mm A')
}

export const getUTCDate = (date: Dayjs) => {
  return date.utc().toISOString()
}

export const getDateFromUTC = (date: string, timezone?: string) =>
  timezone ? dayjs(date).tz(timezone) : dayjs(date).local()

export const validateDates = ({
  arrival,
  departure,
  isSameDateRangeAllowed = false,
  minDate = todayDate,
}: {
  arrival?: Dayjs | string
  departure?: Dayjs | string
  isSameDateRangeAllowed?: boolean
  minDate?: Dayjs | string
}): {
  validArrival: Dayjs
  validDeparture: Dayjs
} => {
  const validDates = {
    validArrival: initialArrivalDate.clone(),
    validDeparture: initialDepartureDate.clone(),
  }

  const parsedArrival = arrival ? dayjs(arrival) : dayjs(null)
  const parsedDeparture = departure ? dayjs(departure) : dayjs(null)
  const parsedMinDate = dayjs(minDate, 'YYYY-MM-DD', true)

  if (parsedArrival.isValid()) {
    if (parsedArrival.isBefore(parsedMinDate, 'day')) {
      validDates.validArrival = parsedMinDate
    } else if (parsedArrival.isAfter(maxDate, 'day')) {
      validDates.validArrival = maxDate.subtract(1, 'd')
    } else {
      validDates.validArrival = parsedArrival
    }

    if (!parsedDeparture.isValid()) {
      validDates.validDeparture = validDates.validArrival.add(1, 'd')
    }
  }

  if (parsedDeparture.isValid()) {
    if (parsedDeparture.isBefore(parsedMinDate, 'day')) {
      validDates.validDeparture = parsedMinDate.add(1, 'd')
    } else if (parsedDeparture.isAfter(maxDate, 'day')) {
      validDates.validDeparture = maxDate
    } else {
      validDates.validDeparture = parsedDeparture
    }

    if (!parsedArrival.isValid()) {
      validDates.validArrival = validDates.validDeparture.subtract(1, 'd')
    }
  }

  if (
    validDates.validArrival.isSame(validDates.validDeparture, 'day') &&
    !isSameDateRangeAllowed
  ) {
    const updatedDeparture = validDates.validDeparture.add(1, 'd')
    validDates.validDeparture = updatedDeparture
  }

  return validDates
}

export const convertDateFromUtcToDayjs = ({
  date,
  timezone,
}: {
  date?: string
  timezone?: string | null
}) => {
  const tz = timezone ?? defaultTimeZone
  return date ? dayjs(getDateFromUTC(date).tz(tz).format('MMM D, YYYY')) : null
}

export const stringTimeToDayjs = (time?: string, date?: Dayjs): Dayjs => {
  const regex = new RegExp(timeRegex)

  if (regex.test(time?.toLocaleUpperCase() ?? '')) {
    const [timeWithoutMeridiem, meridiem] = time?.split(' ') ?? []
    const parsedTime = timeWithoutMeridiem
      .split(':')
      .map(strInt => parseInt(strInt))

    if (meridiem) {
      if (meridiem === 'AM' && parsedTime[0] === 12) {
        parsedTime[0] = 0
      } else if (meridiem === 'PM') {
        const parsedHour = parsedTime[0]
        parsedTime[0] = parsedHour === 12 ? 12 : parsedHour + 12
      }
    }

    const [hour, minute] = parsedTime ?? null
    if (date?.isValid()) {
      return date.set('hour', hour).set('minute', minute)
    } else {
      return dayjs().set('hour', hour).set('minute', minute)
    }
  } else {
    return dayjs(time, 'h:mmA')
  }
}

export const combineDateAndTimeToUTC = ({
  date,
  time,
  timezone = defaultTimeZone,
}: {
  date?: Dayjs
  timezone?: string
  time?: string
}) => {
  const timeDjs = stringTimeToDayjs(time)

  const dateDjs =
    date && dayjs().year(date.year()).month(date.month()).date(date.date())

  if (date && dateDjs?.isValid()) {
    if (time && timeDjs.isValid()) {
      return dateDjs
        .hour(timeDjs.hour())
        .minute(timeDjs.minute())
        .second(0)
        .millisecond(0)
        .tz(timezone, true)
        .toISOString()
    } else {
      return dateDjs
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .tz(timezone, true)
        .toISOString()
    }
  }
  return dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .tz(timezone, true)
    .toISOString()
}

export const getTimeFromUTC = ({
  utcDate,
  timezone,
}: {
  utcDate: string
  timezone: string
}) => {
  const eventFromUTC = getDateFromUTC(utcDate, timezone)
  return eventFromUTC.format(defaultTimeFormat).toString()
}
