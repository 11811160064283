import merge from 'lodash.merge'
import usePlacesAutocomplete from 'use-places-autocomplete'
import type { HookArgs as PlacesAutocompleteOptions } from 'use-places-autocomplete'

const RESTRICTED_COUNTRIES = ['ca', 'mx', 'us']

export const useGeocoderSearch = (config?: PlacesAutocompleteOptions) =>
  usePlacesAutocomplete(
    merge(
      {
        debounce: 300,
        requestOptions: {
          componentRestrictions: {
            country:
              config?.requestOptions?.componentRestrictions?.country ||
              RESTRICTED_COUNTRIES,
          },
        },
      },
      config
    )
  )
