export const localStorageFlagKey = 'tpg_feature_flags'

export const getLocalFlags = () => {
  const flags = localStorage.getItem(localStorageFlagKey)
  if (!flags) return {}
  return JSON.parse(flags)
}

export const updateFlags = flags => {
  localStorage.setItem(localStorageFlagKey, JSON.stringify(flags))
  dispatchEvent(new Event('storage'))
}
