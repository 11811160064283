import type { PopoverProps } from '@travelpass/design-system'
import { Button, MenuItem, Popover } from '@travelpass/design-system'
import type { ButtonVariant } from '@travelpass/design-system/dist/src/components/Button/buttonConstants'
import { dispatchToggleAddToCollectionsModal } from './dispatchToggleAddToCollectionsModal'
import { dispatchToggleAddToGuideModal } from '../Guides/dispatchToggleAddToGuideModal'

interface AddToMenuProps {
  variant?: ButtonVariant
  item: AddToItem
  placement?: PopoverProps['placement']
}

export const AddToMenu = ({
  item,
  variant = 'white',
  placement = 'bottom',
}: AddToMenuProps) => (
  <Popover
    placement={placement}
    trigger={
      <Button size='small' startIcon='addCircleOutline' variant={variant}>
        Add To
      </Button>
    }
  >
    <MenuItem
      label='Add to Guide'
      startIcon='listAlt'
      value='addToGuide'
      onClick={() => dispatchToggleAddToGuideModal({ isModalOpen: true, item })}
    />
    <MenuItem
      label='Add to Collection'
      startIcon='bookmarkBorder'
      value='addToCollection'
      onClick={() =>
        dispatchToggleAddToCollectionsModal({ isModalOpen: true, item })
      }
    />
  </Popover>
)
