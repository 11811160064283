import { Fragment } from 'react'
import { IconButton, Divider } from '@travelpass/design-system'
import { lucencyNumber } from 'src/config/analytics/lucencyUtils'
import type { GuestsType } from 'src/constants/user'
import {
  maxAdultGuests,
  maxKidGuests,
  minAdultGuests,
  minKidGuests,
} from 'src/constants/user'
import { isLastIndex } from 'src/utils'
import {
  guestsPopoverCss,
  guestsPopoverItemCss,
  guestsPopoverItemDetailsCss,
  guestsPopoverItemDetailsSubtitleCss,
  guestsPopoverItemDetailsTitleCss,
  guestsPopoverItemStepperCss,
  guestsPopoverItemStepperTitleCss,
} from './guestsPopoverStyles'

interface GuestsPopoverProps {
  guests: GuestsType
  onChange(guests: GuestsType): void
}

export const GuestsPopover = ({ guests, onChange }: GuestsPopoverProps) => {
  const [adults, kids] = guests

  const constructGuestsItems = [
    {
      maxValue: maxAdultGuests,
      minValue: minAdultGuests,
      subtitle: 'Ages 18 or above',
      title: 'Adults:',
      value: adults,
    },
    {
      maxValue: maxKidGuests,
      minValue: minKidGuests,
      subtitle: 'Ages 0-17',
      title: 'Children:',
      value: kids,
    },
  ]

  const onGuestsItemButtonClick = (index, value) => {
    const clonedGuests = guests.slice()
    clonedGuests[index] = value
    // track guests to lucency
    lucencyNumber({
      rooms: [{ adults: clonedGuests[0], kids: clonedGuests[1] }],
    })
    onChange(clonedGuests)
  }

  const constructedGuestsItems = constructGuestsItems.map(
    ({ maxValue, minValue, subtitle, title, value }, index) => (
      <Fragment key={`guests-popover-${index}`}>
        <div className={guestsPopoverItemCss}>
          <div className={guestsPopoverItemDetailsCss}>
            <p className={guestsPopoverItemDetailsTitleCss}>{title}</p>
            <p className={guestsPopoverItemDetailsSubtitleCss}>{subtitle}</p>
          </div>
          <div className={guestsPopoverItemStepperCss}>
            <IconButton
              icon='removeCircleOutline'
              isDisabled={minValue === value}
              onClick={() => onGuestsItemButtonClick(index, value - 1)}
            />
            <div className={guestsPopoverItemStepperTitleCss}>{value}</div>
            <IconButton
              icon='addCircleOutline'
              isDisabled={maxValue === value}
              onClick={() => onGuestsItemButtonClick(index, value + 1)}
            />
          </div>
        </div>
        {!isLastIndex(constructGuestsItems, index) && <Divider />}
      </Fragment>
    )
  )

  const constructedGuestsPopover = (
    <div className={guestsPopoverCss}>{constructedGuestsItems}</div>
  )

  return constructedGuestsPopover
}
