import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { isMobile } from 'react-device-detect'
import { TENANT_ID_DESKTOP, TENANT_ID_MOBILE } from 'src/constants'
import { isProdEnv } from 'src/utils'
import { pushDataToDataLayer } from './googleTagManagerIntegration'

interface Room {
  adults: number
  kids: number
}
interface SessionDataProps {
  //rooms was defined as an array 'cause in the future there's gonna be multi-room booking
  rooms?: Array<Room>
  averageOrderValue?: number
  currency?: string
  checkin?: Dayjs
  checkout?: Dayjs
  isUserLoggedin?: boolean
  ratesAvailability?: 'available' | 'unavailable'
  roomAvailability?: 'available' | 'unavailable'
  cugAvailability?: 'available' | 'unavailable'
  hotelsViewed?: Array<string>
  webbedsDevicePayload?: string | null
  vwo_uuid?: string
  xRsClientCode?: string
  userVwoExperiments?: {
    experimentId: string
    variationId: string
    abTestName: string
    variationName: string
  }
}

export const onPhoneNumberClick = (phoneNumber: string) =>
  pushDataToDataLayer('call', {
    phone_number: phoneNumber,
  })

const getDefaultPhoneNumber = () => {
  if (isProdEnv) {
    return isMobile ? '855-391-0988' : '855-391-0213'
  }
  return '833-378-2498'
}

// prevent tracking the same url repeatedly
const getAndUpdateSessionURLs = (): string[] => {
  const KEY = 'lucency::sessionURLs'
  const sessionURLs = JSON.parse(window.sessionStorage.getItem(KEY)) ?? []
  if (sessionURLs.slice(-1)[0] !== window.location.href) {
    sessionURLs.push(window.location.href)
    window.sessionStorage.setItem(KEY, JSON.stringify(sessionURLs))
    return sessionURLs
  }
  return []
}

const getChildrenAges = (kidsAmount: number) => {
  let ages = {
    '1': 'n/a',
    '2': 'n/a',
    '3': 'n/a',
    '4': 'n/a',
  }
  for (let i = 0; i < kidsAmount; i++) {
    ages[i + 1] = 5 //The default child's age
  }
  return ages
}

const getRoomInfo = (rooms: Room[]) => {
  let roomsInfo = {
    '1': 'n/a',
    '2': 'n/a',
    '3': 'n/a',
    '4': 'n/a',
    '5': 'n/a',
    '6': 'n/a',
    '7': 'n/a',
    '8': 'n/a',
  }
  rooms?.map(({ adults, kids }, index) => {
    roomsInfo[index + 1] = {
      adults,
      children_ages: getChildrenAges(kids),
    }
  })
  return roomsInfo
}

export const getTenantID = () => {
  return isMobile ? TENANT_ID_MOBILE : TENANT_ID_DESKTOP
}

const CAMPAIGN_ID = isProdEnv ? '54679' : '54687'
const DEFAULT_PHONE_NUMBER = getDefaultPhoneNumber()
export const lucencyNumber = (sessionData?: SessionDataProps) => {
  const {
    rooms,
    averageOrderValue,
    checkin,
    checkout,
    isUserLoggedin,
    ratesAvailability,
    roomAvailability,
    cugAvailability,
    hotelsViewed,
    currency,
    webbedsDevicePayload,
    vwo_uuid,
    xRsClientCode,
    userVwoExperiments,
  } = sessionData ?? {}
  let lucencyPhoneNumber = ''
  let lucencyErrorMessage = ''

  // temporary solution as the use of lucency in this repo isn't great as in nitecrawler
  const rs_client = Cookies.get('RS-CLIENT')
  const forterPayload = xRsClientCode || rs_client

  let sessionDataToAppend = Object.assign(
    {},
    averageOrderValue !== undefined && {
      aov: averageOrderValue,
    },
    currency !== undefined && { currency },
    checkin !== undefined && {
      check_in_date: dayjs(checkin).format('YYYY-MM-DD'),
      booking_window: dayjs(checkin).diff(dayjs(), 'day'),
    },
    checkout !== undefined && {
      check_out_date: dayjs(checkout).format('YYYY-MM-DD'),
    },
    isUserLoggedin !== undefined && { is_user_logged_in: isUserLoggedin },
    ratesAvailability !== undefined && { unavailability: ratesAvailability },
    roomAvailability !== undefined && { room_availability: roomAvailability },
    cugAvailability !== undefined && { cug_availability: cugAvailability },
    hotelsViewed !== undefined && {
      hotels_visited: hotelsViewed,
      last_hotel: hotelsViewed[hotelsViewed.length - 1],
    },
    webbedsDevicePayload !== undefined && {
      webbeds_device_payload: webbedsDevicePayload,
    },
    getAndUpdateSessionURLs().length > 0 && {
      urls: [window.location.href],
    },
    rooms?.length > 0 && {
      room_info: getRoomInfo(rooms),
    },
    {
      attribution: { tenant_id: getTenantID() },
    },
    {
      application: 'Travelpass',
      requesting_url: window.location.href,
    },
    vwo_uuid !== undefined && { vwo_uuid },
    forterPayload && { x_rs_client_code: forterPayload },
    userVwoExperiments !== undefined && {
      user_vwo_experiments: [userVwoExperiments],
    }
  )

  window?.lucency?.(
    'write',
    sessionDataToAppend,
    CAMPAIGN_ID,
    phoneNumber => {
      lucencyPhoneNumber = phoneNumber
    },
    errorMessage => {
      lucencyErrorMessage = errorMessage
    }
  )
  return lucencyPhoneNumber || DEFAULT_PHONE_NUMBER
}
