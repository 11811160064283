import { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { featureFlagNames } from 'src/config/feature-flags/featureFlags'
import { getLocalFlags } from 'src/config/feature-flags/utils'

export const useFlag = (flagKey: featureFlagNames): boolean => {
  const ldFlags = useFlags()

  const getFlagValue = () => getLocalFlags()?.[flagKey] ?? ldFlags?.[flagKey]

  const [flagValue, setFlagValue] = useState(getFlagValue())

  const localStorageEventListener = () => setFlagValue(getFlagValue())

  useEffect(() => {
    window.addEventListener('storage', localStorageEventListener)

    return () =>
      window.removeEventListener('storage', localStorageEventListener)
  }, [])

  return flagValue
}
