import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { USER_PROFILE_FRAGMENT } from './fragments/userProfileFragment'

export const GET_USER_PROFILE_BY_ACCOUNT_HANDLE = gql(`
  query ProfileByAccountHandle($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      ...UserProfileFields
    }
  } 
`)

export const useProfileByAccountHandle = (accountHandle: string) =>
  useQuery(GET_USER_PROFILE_BY_ACCOUNT_HANDLE, {
    variables: { accountHandle: accountHandle?.toLowerCase() },
    skip: !accountHandle,
    errorPolicy: 'all',
  })
