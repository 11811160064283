import type { CSSProperties } from 'react'
import classNames from 'classnames'

interface ProgressCircleProps {
  /** An accessibility label for this node. */
  ariaLabel?: string
  /**
   * Whether to show the value as text in the progress bar.
   * @default false
   */
  hideValue?: boolean
  /** A label to show instead of the value in . */
  label?: string
  /**
   * The largest value allowed for the input.
   * @default 100
   */
  maxValue?: number
  /**
   * The smallest value allowed for the input.
   * @default 0
   */
  minValue?: number
  /** The current value (controlled). */
  value: number
}

export const ProgressCircle = ({
  ariaLabel,
  label,
  hideValue = false,
  maxValue = 100,
  minValue = 0,
  value,
}: ProgressCircleProps) => {
  let angle: number = 0
  let subMask1Style: CSSProperties = {}
  let subMask2Style: CSSProperties = {}
  /**
   * Forces value to the closest min/max if it's outside. Also forces it to the closest valid step.
   *
   * @see https://github.com/adobe/react-spectrum/blob/ee51290b98fdc7cad31957ed2dd0a486eb0c1427/packages/%40react-stately/utils/src/number.ts#L16-L19
   */
  const clampedValue = Math.min(Math.max(value || 0, minValue), maxValue)
  const percentage = ((clampedValue - minValue) / (maxValue - minValue)) * 100
  const percentageWithUnit = `${percentage}%`

  if (percentage >= 0 && percentage <= 50) {
    angle = -180 + (percentage / 50) * 180
    subMask1Style.transform = `rotate(${angle}deg)`
    subMask2Style.transform = 'rotate(-180deg)'
  } else if (percentage > 50) {
    angle = -180 + ((percentage - 50) / 50) * 180
    subMask1Style.transform = 'rotate(0deg)'
    subMask2Style.transform = `rotate(${angle}deg)`
  }

  if (import.meta.env.NODE_ENV !== 'production' && !ariaLabel) {
    console.warn(
      'Consider providing <ProgressCircle /> with a aria-label prop, which is only surfaced to assistive technology (AT). A good label makes a difference.'
    )
  }

  return (
    <div
      aria-label={ariaLabel}
      aria-valuemax={maxValue}
      aria-valuemin={minValue}
      aria-valuenow={clampedValue}
      aria-valuetext={percentageWithUnit}
      className='relative inline-block h-14 w-14'
      role='progressbar'
    >
      <div className='b-solid b-mintLight b-4 rounded-1/2 h-14 w-14' />
      <div className='absolute left-0 top-0 h-full w-full'>
        <div className={classNames(progressCircleFillMaskCss, 'rotate-180')}>
          <div
            className={progressCircleFillSubMaskCss}
            data-testid='fillSubMask1'
            style={subMask1Style}
          >
            <div className={progressCircleFillCss} />
          </div>
        </div>
        <div className={classNames(progressCircleFillMaskCss, 'rotate-0')}>
          <div
            className={progressCircleFillSubMaskCss}
            data-testid='fillSubMask2'
            style={subMask2Style}
          >
            <div className={progressCircleFillCss} />
          </div>
        </div>
      </div>
      {!hideValue && (
        <p className='color-black font-roboto type-h4-mobile absolute left-0 top-0 flex h-full w-full flex-col justify-center text-center'>
          {label ?? percentageWithUnit}
        </p>
      )}
    </div>
  )
}

const progressCircleFillCss = 'b-teal b-solid z-1 b-4 h-14 rounded-1/2 w-14'

const progressCircleFillMaskCss =
  'absolute h-full origin-[100%_center] of-hidden w-1/2'

const progressCircleFillSubMaskCss =
  'absolute h-full origin-[100%_center] of-hidden w-full'
