import { HorizontalStep } from './HorizontalStep'

interface HorizontalStepperProps {
  completedSteps: number
  steps: string[]
}

export const HorizontalStepper = ({
  completedSteps: initialCompletedSteps,
  steps,
}: HorizontalStepperProps) => {
  const maxSteps = steps?.length
  const completedSteps = Math.min(initialCompletedSteps, maxSteps)
  const position = `${50 / maxSteps}%`
  const progressWidth = `${Math.min((completedSteps / maxSteps) * 100, ((maxSteps - 1) / maxSteps) * 100)}%`

  return (
    <div aria-label='progress' className='relative'>
      <div
        className='bg-grey-500 absolute top-2.5 h-1'
        style={{ left: position, right: position }}
      />
      <div
        className='bg-orange absolute top-2.5 h-1'
        data-testid='progress-bar'
        style={{
          left: position,
          right: position,
          width: progressWidth,
        }}
      />
      <ol
        className='relative m-0 grid list-none p-0'
        style={{ gridTemplateColumns: `repeat(${maxSteps}, minmax(0, 1fr))` }}
      >
        {steps.map((step, index) => {
          return (
            <HorizontalStep
              key={index}
              isActive={index == completedSteps}
              isCompleted={index < completedSteps}
            >
              {step}
            </HorizontalStep>
          )
        })}
      </ol>
    </div>
  )
}
