import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ARCHIVE_GUIDE = gql(`
  mutation ArchiveGuide($guideInput: ArchiveGuideInput!) {
    archiveGuide(guideInput: $guideInput) {
      guide {
        id
        status
        archivedAt
      }
    }
  }`)

export const useArchiveGuide = () => useMutation(ARCHIVE_GUIDE)
