import travelpassBeachLogo from './travelpassBeachLogo.png'
import travelpassPrimaryLogo from './travelpassPrimaryLogo.png'

interface TravelpassLogoProps {
  showPrimaryLogo?: boolean
}

export const TravelpassLogo = ({
  showPrimaryLogo = true,
}: TravelpassLogoProps) => (
  <>
    <span className='sr-only'>Travelpass</span>
    <img
      alt=''
      className='h-auto max-w-full'
      fetchPriority='high'
      src={showPrimaryLogo ? travelpassPrimaryLogo : travelpassBeachLogo}
    />
  </>
)
