import type { ReactNode } from 'react'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'

interface HorizontalStepProps {
  children: ReactNode
  isActive: boolean
  isCompleted: boolean
}

export const HorizontalStep = ({
  children,
  isActive,
  isCompleted,
}: HorizontalStepProps) => (
  <li className='flex flex-col items-center gap-1 text-center'>
    <div
      className={classNames(
        'rounded-50% h-6 w-6 border-4 border-solid bg-white',
        {
          'border-orange': isActive || isCompleted,
          'border-grey-500': !isCompleted,
        }
      )}
      data-testid='HorizontalStepCircle'
    >
      {isCompleted && !isActive && (
        <div className='color-orange' data-testid='checkIcon'>
          <Icon name='check' size='small' />
        </div>
      )}
    </div>
    <p className='c-grey-700 font-400 type-small-text-mobile lg:font-archivo lg:type-button-desktop'>
      {children}
    </p>
  </li>
)
