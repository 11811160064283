import { type ResultsCardReviewsProps } from './types'

const EXCELLENT = 9
const VERY_GOOD = 7
const GOOD = 5

const getReviewAverage = ({
  average = 0,
  denominator = 1,
  total,
  variant,
}: {
  average: ResultsCardReviewsProps['average']
  denominator?: number
  total?: ResultsCardReviewsProps['total']
  variant?: ResultsCardReviewsProps['variant']
}): string => {
  let averageTitle = ''

  if (!average || average < 0) return averageTitle

  if (average >= EXCELLENT / denominator) {
    averageTitle = 'Excellent'
  } else if (average >= VERY_GOOD / denominator) {
    averageTitle = 'Very Good'
  } else if (average >= GOOD / denominator) {
    averageTitle = 'Good'
  } else {
    averageTitle = 'Poor'
  }

  if (average >= 5 / denominator && variant === 'default' && total)
    averageTitle += ' /'

  return averageTitle
}

const getReviewTotal = ({
  total,
  variant,
}: Partial<ResultsCardReviewsProps>): null | string => {
  if (!total || total === 0) return

  if (variant === 'minimal') return `(${total})`

  if (total > 1) return `${total} reviews`

  return `${total} review`
}

export { getReviewAverage, getReviewTotal }
