import {
  initialGeocoderLatitude,
  initialGeocoderLongitude,
  initialGeocoderPlaceName,
} from 'src/constants/user'

interface ConstructAddressProps {
  addressFirstLine: string
  addressSecondLine?: string
  city: string
  hideCountry?: boolean
  hideZipcode?: boolean
  state: string
  country?: string
  zipcode?: string
}

const constructAddress = ({
  addressFirstLine,
  addressSecondLine,
  city,
  hideCountry = false,
  hideZipcode = false,
  state,
  country,
  zipcode,
}: ConstructAddressProps) => {
  const renderAddressFirstLine = addressFirstLine ? `${addressFirstLine}, ` : ''
  const renderAddressSecondLine = addressSecondLine
    ? `${addressSecondLine}, `
    : ''
  const renderCity = city ? `${city}, ` : ''
  const renderState = state ? `${state} ` : ''
  const renderCountry = !hideCountry && country ? country : ''
  const renderZipcode = () => {
    if (hideZipcode) return ''
    if (zipcode && country) return `${zipcode}, `
    if (zipcode && !country) return `${zipcode}`
    return ''
  }

  return `${renderAddressFirstLine}${renderAddressSecondLine}${renderCity}${renderState}${renderZipcode()}${renderCountry}`.trim()
}

const maxLat = 90
const minLat = -90
const maxLon = 180
const minLon = -180

interface validateLatLonArgs {
  latitude?: number | string
  longitude?: number | string
}

interface validateLatLonResponse {
  validLat: number
  validLon: number
  resetToDefaultLatLon: boolean
}

const validateLatLon = ({
  latitude,
  longitude,
}: validateLatLonArgs): validateLatLonResponse => {
  const latFloat = parseFloat(String(latitude))
  const lonFloat = parseFloat(String(longitude))

  const validLatLon = {
    validLat: initialGeocoderLatitude,
    validLon: initialGeocoderLongitude,
    resetToDefaultLatLon: true,
  }

  const isValidLat = latFloat <= maxLat && latFloat >= minLat
  const isValidLon = lonFloat <= maxLon && lonFloat >= minLon

  if (isValidLat && isValidLon) {
    validLatLon.validLat = latFloat
    validLatLon.validLon = lonFloat
    validLatLon.resetToDefaultLatLon = false
  }

  return validLatLon
}

interface validateLocationArgs extends validateLatLonArgs {
  location?: string
}

interface validateLocationResponse extends validateLatLonResponse {
  validLocation: string
  resetToDefaultLocation: boolean
}

/**
 * WARNING only checks that if the lat and long were reset to default
 * that the location gets reset to default
 *
 * IF given an empty string and a valid Lat and Lon it will return an
 * empty string as a valid location
 */
const validateLocation = ({
  location,
  latitude,
  longitude,
}: validateLocationArgs): validateLocationResponse => {
  const { validLat, validLon, resetToDefaultLatLon } = validateLatLon({
    latitude,
    longitude,
  })

  const validLocationLatLon: validateLocationResponse = {
    validLocation: initialGeocoderPlaceName,
    resetToDefaultLocation: true,
    validLat,
    validLon,
    resetToDefaultLatLon,
  }

  if (!resetToDefaultLatLon) {
    validLocationLatLon.resetToDefaultLocation = false
    if (location) {
      validLocationLatLon.validLocation = location
    } else {
      validLocationLatLon.validLocation = ''
    }
  }

  return validLocationLatLon
}

export { constructAddress, validateLatLon, validateLocation }
