import { Link, Modal } from '@travelpass/design-system'
import {
  lucencyNumber,
  onPhoneNumberClick,
} from 'src/config/analytics/lucencyUtils'

interface LucencySupportModalProps {
  onClose(): void
}

export const LucencySupportModal = ({ onClose }: LucencySupportModalProps) => {
  const phoneNumber = lucencyNumber()

  return (
    <Modal size='medium' title='Support' onDismiss={onClose}>
      <div className='type-body-1 c-grey-800'>
        <div>
          For help from our dedicated customer support team, please call{' '}
          <Link
            label={phoneNumber}
            onClick={() => {
              onPhoneNumberClick(phoneNumber)
              window.location.href = `tel:${phoneNumber}`
            }}
          />
          . They are available Monday through Friday between 9:00am and 5:00pm
          MT.
        </div>
      </div>
    </Modal>
  )
}
